<template>
  <div style="min-height: calc(100vh - 60px); margin: 10px 0;">
    <div>
      <div style="padding-left: 30px; ">

        <el-card style="margin: 10px 0">
          <div style="display: flex">
            <div style="padding-left: 50px; flex: 1">
              <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
                <span style="font-size: 24px">{{ form.name }}</span>
              </div>
              <div style="line-height: 30px" @click="$router.push('/front/homeDetail?id=' + item.id)">
                <div><b style="margin-right: 10px">作者：</b>{{ form.author }}</div>
                <div><b style="margin-right: 10px">出版社：</b>{{ form.publisher }}</div>
                <div><b style="margin-right: 10px">出版年份：</b>{{ form.publishedYear }}</div>
                <div><b style="margin-right: 10px">类型：</b>{{ form.typeText.name }}</div>
                <div><b style="margin-right: 10px">所属用户：</b>{{ form.user.username + '(' + form.user.nickname + ')' }}
                </div>

                <div><b style="margin-right: 10px">标签：</b>
                  <el-tag v-for="tag in form.tagText" :key="tag">{{ tag.name }}</el-tag>
                </div>
                <div><b style="margin-right: 10px">描述：</b>
                  <mavon-editor
                      class="md"
                      :value="form.remark"
                      :subfield="false"
                      :defaultOpen="'preview'"
                      :toolbarsFlag="false"
                      :editable="false"
                      :scrollStyle="true"
                      :ishljs="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "HomeDetail",
  data() {
    return {
      id: this.$route.query.id,
      comments: [],
      form: {},
      dialogFormVisible: false,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      praise: false
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/book/" + this.id).then(res => {
        this.form = res.data
      })
    },
  }
}
</script>

<style scoped>

</style>
